import React from 'react';

import { Box, TableCell, TableRow, Typography } from '@material-ui/core';
import clsx from 'clsx';
import Checkbox from 'components/Checkbox';
import Button from 'components/V2/Button';
import PropTypes from 'prop-types';

import classes from './listingsTable.module.css';

const SelectionInfoRow = ({
    isChecked,
    selectedCount,
    rowsCount,
    totalListingsCount,
    isSelectAll,
    onSelectAll,
    onClear,
    onChange,
    disableSelection,
}) => {
    const renderSelectAllText = () => (
        <Typography className={classes.selectionInfoTextCenter}>
            All <span className={classes.listingsCount}>{totalListingsCount}</span> listings are
            selected.
        </Typography>
    );

    const renderPartialSelectionText = () => (
        <Typography className={classes.selectionInfoTextCenter}>
            <span className={classes.listingsCount}>{selectedCount}&nbsp;</span> of{' '}
            {totalListingsCount} listings are selected.
            {!isSelectAll && (
                <Button className={classes.selectAllBlue} onClick={onSelectAll}>
                    Select all {totalListingsCount} listings
                </Button>
            )}
        </Typography>
    );

    const renderFullSelectionText = () => (
        <Box className={classes.selectionInfoTextCenterWrapper}>
            <Typography className={classes.selectionInfoTextCenter}>
                All <span className={classes.listingsCount}>{rowsCount}</span> of{' '}
                {totalListingsCount} listings which are showing are selected.
            </Typography>
            {!isSelectAll && (
                <Button className={classes.selectAllBlue} onClick={onSelectAll}>
                    Select all {totalListingsCount} listings
                </Button>
            )}
        </Box>
    );

    const renderButtons = () => (
        <Button className={clsx(classes.selectAllBlue, classes.clearAllBtn)} onClick={onClear}>
            Clear Selection
        </Button>
    );

    const renderPartialOrFullSelectionText =
        selectedCount !== rowsCount ? renderPartialSelectionText() : renderFullSelectionText();

    return (
        <TableRow
            className={clsx(classes.selectionInfoRow, {
                [classes.selectionGreyBg]: selectedCount !== 0,
            })}
        >
            <TableCell className={classes.selectionInfoCheckboxCell}>
                <Checkbox
                    checked={isChecked}
                    onChange={onChange}
                    disabled={disableSelection}
                    disableRipple
                    disableFocusRipple
                    classes={{
                        root: classes.checkbox,
                        checked: classes.checked,
                    }}
                />
            </TableCell>
            <TableCell className={classes.selectionInfoCell}>
                {selectedCount === 0 ? (
                    <Typography className={classes.selectionInfoTextRight}>
                        <span className={classes.listingsCount}>{totalListingsCount}</span> listings
                    </Typography>
                ) : (
                    <Box className={classes.wrapper}>
                        <Box className={classes.selectionTextWrapper}>
                            {isSelectAll && isChecked
                                ? renderSelectAllText()
                                : renderPartialOrFullSelectionText}
                        </Box>
                        {renderButtons()}
                    </Box>
                )}
            </TableCell>
        </TableRow>
    );
};

SelectionInfoRow.propTypes = {
    selectedCount: PropTypes.number,
    rowsCount: PropTypes.number,
    totalListingsCount: PropTypes.number,
    isSelectAll: PropTypes.bool,
    onSelectAll: PropTypes.func,
    onClear: PropTypes.func,
    isChecked: PropTypes.bool,
    onChange: PropTypes.func,
    disableSelection: PropTypes.bool,
};

export default SelectionInfoRow;
