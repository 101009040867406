/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { NavLink, useLocation, useHistory } from 'react-router-dom';

import {
    ListItem,
    ListItemIcon,
    ListItemText,
    IconButton,
    Hidden,
    Grid,
    Collapse,
    List,
} from '@material-ui/core/';
// assets
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import { isRouteRestricted } from 'helper/utility';
import PropTypes from 'prop-types';
import routeEnpoints from 'route/endpoints';

import { ReactComponent as LogoutIcon } from 'asset/IconsPack/at_log-out-01.svg';

//components
import './Sidebar.scss';

const Sidebar = (props) => {
    const { menuItem } = props;
    let location = useLocation();
    const history = useHistory();
    const [open, setOpen] = useState(false);

    // verifies if routeName is the one active
    function activeRoute(routeName) {
        // home and billing page include same '/dashboard' word in a route so separate condition to make sure both section don't highlight at same time
        if (location.pathname === '/dashboard' || location.pathname === '/billing/dashboard') {
            return location.pathname === routeName;
        } else {
            return location.pathname.includes(routeName);
        }
    }

    function subActiveRoute() {
        if (['/records', '/watchlist', '/search'].includes(location.pathname)) return true;
    }

    const logoutClick = () => {
        history.push(routeEnpoints.logout);
    };

    useEffect(() => {
        if (['/records', '/watchlist', '/search'].includes(location.pathname)) {
            setOpen('True Continuous Monitoring');
        } else if (location.pathname.indexOf('guides/') === 1) {
            setOpen('Guides');
        }
    }, []);

    return (
        <>
            {/* desktop sidebar */}
            <Hidden mdDown>
                <div className="sideNav-wrapper">
                    {menuItem.map((item, index) => {
                        const restrictedRoute = isRouteRestricted(item.label, props.authData);

                        if (restrictedRoute) {
                            return null;
                        } else if (item.subMenu) {
                            return (
                                <div key={`${item.label}_${index}`} className="sidebarNav">
                                    <ListItem
                                        button
                                        onClick={() =>
                                            setOpen(open === item.label ? null : item.label)
                                        }
                                        className={clsx(
                                            'sidebarListItem',
                                            subActiveRoute() ? 'activePath' : 'inactive'
                                        )}
                                    >
                                        <ListItemIcon className="sidebarItemIcon">
                                            {item.icon}
                                        </ListItemIcon>
                                        <ListItemText className="sidebarItemLabel">
                                            <Grid container alignItems="center">
                                                <Grid item xs={10}>
                                                    {item.label}
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <span>
                                                        {open === item.label ? (
                                                            <ExpandLess />
                                                        ) : (
                                                            <ExpandMore />
                                                        )}
                                                    </span>
                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                    </ListItem>

                                    <Collapse
                                        className="collapseWrapper"
                                        in={open === item.label}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <List component="div" disablePadding className="subList">
                                            {item.subMenu.map((item, index) => {
                                                return (
                                                    <a
                                                        target="_blank"
                                                        href={item.path}
                                                        key={`${item.label}_${index}`}
                                                        className="subSidebarNav"
                                                        rel="noreferrer"
                                                    >
                                                        <ListItem
                                                            className={clsx(
                                                                'subSidebarListItem',
                                                                activeRoute(item.path)
                                                                    ? 'activePath'
                                                                    : 'inactive'
                                                            )}
                                                        >
                                                            <ListItemIcon className="sidebarItemIcon">
                                                                {item.icon}
                                                            </ListItemIcon>
                                                            <ListItemText className="sidebarItemLabel">
                                                                {item.label}
                                                            </ListItemText>
                                                        </ListItem>
                                                    </a>
                                                );
                                            })}
                                        </List>
                                    </Collapse>
                                </div>
                            );
                        } else {
                            return (
                                <NavLink
                                    to={item.path}
                                    key={`${item.label}_${index}`}
                                    className="sidebarNav"
                                >
                                    <ListItem
                                        className={clsx(
                                            'sidebarListItem',
                                            activeRoute(item.path) ? 'activePath' : 'inactive'
                                        )}
                                    >
                                        <ListItemIcon className="sidebarItemIcon">
                                            {item.icon}
                                        </ListItemIcon>
                                        <ListItemText className="sidebarItemLabel">
                                            {item.label}
                                        </ListItemText>
                                    </ListItem>
                                </NavLink>
                            );
                        }
                    })}
                </div>
                <div className="sidebarLogout">
                    <IconButton onClick={logoutClick} className="sidebarLogoutIcon">
                        <LogoutIcon className="logoutIcon" />
                        <span className="sidebarLogoutLabel">Log out</span>
                    </IconButton>
                </div>
            </Hidden>

            {/* mobile & tablet sidebar */}
            <Hidden only={['lg', 'xl']}>
                {menuItem.map((item, index) => {
                    const restrictedRoute = isRouteRestricted(item.label, props.authData);

                    if (restrictedRoute) {
                        return null;
                    } else if (item.subMenu) {
                        return (
                            <div key={`${item.label}_${index}`} className="mobileSidebar">
                                <ListItem
                                    button
                                    component="li"
                                    onClick={() => setOpen(open === item.label ? null : item.label)}
                                    className={clsx(
                                        'sidebarListItem',
                                        subActiveRoute() ? 'activePath' : 'inactive'
                                    )}
                                >
                                    <Grid item xs={2}>
                                        <ListItemIcon className="sidebarItemIcon">
                                            {item.icon}
                                        </ListItemIcon>
                                    </Grid>
                                    <Grid container item alignItems="center" xs={10}>
                                        <ListItemText className="sidebarItemLabel">
                                            <Grid container alignItems="center">
                                                <Grid item xs={10}>
                                                    {item.label}
                                                </Grid>
                                                <Grid
                                                    container
                                                    item
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    xs={2}
                                                >
                                                    <span>
                                                        {open === item.label ? (
                                                            <ExpandLess />
                                                        ) : (
                                                            <ExpandMore />
                                                        )}
                                                    </span>
                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                    </Grid>
                                </ListItem>

                                <Collapse
                                    className="collapseWrapper"
                                    in={open === item.label}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    <List component="div" disablePadding className="subList">
                                        {item.subMenu.map((item, index) => (
                                            <a
                                                className="mobileSidebar"
                                                target="_blank"
                                                href={item.path}
                                                key={`${item.label}_${index}`}
                                                rel="noreferrer"
                                            >
                                                <ListItem
                                                    className={clsx(
                                                        'sidebarListItem',
                                                        activeRoute(item.path)
                                                            ? 'subPathActive'
                                                            : 'subPathInactive'
                                                    )}
                                                >
                                                    <Grid item container alignItems="center" xs={2}>
                                                        <ListItemIcon className="sidebarItemIcon">
                                                            {item.icon}
                                                        </ListItemIcon>
                                                    </Grid>
                                                    <Grid item xs={10}>
                                                        <ListItemIcon className="sidebarItemLabel">
                                                            {item.label}
                                                        </ListItemIcon>
                                                    </Grid>
                                                </ListItem>
                                            </a>
                                        ))}
                                    </List>
                                </Collapse>
                            </div>
                        );
                    } else {
                        return (
                            <NavLink
                                to={item.path}
                                key={`${item.label}_${index}`}
                                className="mobileSidebar"
                            >
                                <ListItem
                                    className={clsx(
                                        'sidebarListItem',
                                        activeRoute(item.path) ? 'activePath' : 'inactive'
                                    )}
                                >
                                    <Grid container alignItems="center">
                                        <Grid item xs={2}>
                                            <ListItemIcon className="sidebarItemIcon">
                                                {item.icon}
                                            </ListItemIcon>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <ListItemText className="sidebarItemLabel">
                                                {item.label}
                                            </ListItemText>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            </NavLink>
                        );
                    }
                })}
                <div className="sidebarLogout">
                    <IconButton onClick={logoutClick} className="sidebarLogoutIcon">
                        <LogoutIcon className="logoutIcon" />
                        <span className="sidebarLogoutLabel">Log out</span>
                    </IconButton>
                </div>
            </Hidden>
        </>
    );
};

Sidebar.propTypes = {
    menuItem: PropTypes.array,
    authData: PropTypes.object,
};

export default Sidebar;
