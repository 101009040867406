import styles from './index.module.css';
import MuiButton from '@material-ui/core/Button';
import { cva } from 'class-variance-authority';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const variants = cva(styles.base, {
    variants: {
        //extend as more intents are introduced
        intent: {
            primary: styles.primary,
            secondary: styles.secondary,
        },
        size: {
            md: styles.md,
            lg: styles.lg,
            xl: styles.xl,
            xxl: styles.xxl,
        },
    },
});

const Button = ({ className, intent, size, ...props }) => {
    return <MuiButton className={clsx(variants({ intent, size }), className)} {...props} />;
};

Button.propTypes = {
    className: PropTypes.string,
    //extend as more types are introduced
    intent: PropTypes.oneOf(['primary', 'secondary']),
    size: PropTypes.oneOf(['md', 'lg', 'xl', 'xxl']),
};

export default Button;
