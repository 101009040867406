/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import { Grid, Hidden, Typography, Switch } from '@material-ui/core';
import clsx from 'clsx';
import Button from 'components/Button';
// components
import HistoryBackArrow from 'components/HistoryBackArrow';
import VerifyAccessLevel from 'components/VerifyAccessLevel';
import { useFormik } from 'formik';
import { adminTypes } from 'helper/constant';
import { checkAdminAccess } from 'helper/utility';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import classes from '../../index.module.css';

import CredentialInput from '../../components/CredentialInput';

const validationSchema = yup.object({
    apiKey: yup.string('Enter API Key').required('API Key is required'),
    accountId: yup.string('Enter API Key').required('Account ID is required'),
});

const Hostaway = ({
    token,
    uid,
    history,
    active,
    settings,
    settingsValues,
    settingAction,
    settingGetAction,
    settingPostAction,
    activeAction,
    loggedInAdminRole,
    openToast,
}) => {
    const [isActive, setIsActive] = useState(false);
    const [apiKey, setApiKey] = useState('');
    const [accountId, setAccountId] = useState('');

    const { COMPANY_OWNER, COMPANY_ADMIN } = adminTypes;

    const settingId = active?.find((key) => key.name === 'Hostaway').id;
    const switchHandler = (e) => {
        setIsActive(e.target.checked);
    };

    useEffect(() => {
        if (settingId) {
            settingAction({ id: settingId }, { loader: true }, token);

            settingGetAction(
                {
                    companyAccessCode: uid,
                    integration_id: settingId,
                },
                { loader: true },
                token
            );
        } else if (!active) {
            // if no setting id has been found, fetch the list of active integrations once more.
            activeAction({ companyAccessCode: uid }, { loader: false }, token);
        }
    }, [settingId]);

    useEffect(() => {
        setAccountId(settingsValues?.[2]?.value || '');
        setApiKey(settingsValues?.[1]?.value || '');
        setIsActive((settingsValues && !!Number(settingsValues?.[0]?.value)) || false);
    }, [settingsValues]);

    const formik = useFormik({
        initialValues: {
            apiKey: apiKey,
            accountId: accountId,
        },
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            const newSettings = settings.map((setting) => {
                switch (setting.name) {
                    case 'Active':
                        return {
                            ...setting,
                            value: isActive,
                        };
                    case 'AccountId':
                        return {
                            ...setting,
                            value: values.accountId,
                        };
                    case 'ApiKey':
                        return {
                            ...setting,
                            value: values.apiKey,
                        };
                    case 'accessToken':
                        return {
                            ...setting,
                            value: settingsValues?.[3]?.value ?? '',
                        };
                    default:
                        return {
                            ...setting,
                            value: '',
                        };
                }
            });

            settingPostAction(
                {
                    companyAccessCode: uid,
                    settings: newSettings,
                    key: {
                        id: settingId,
                    },
                },
                { loader: true },
                token,
                () => {
                    openToast({
                        variant: 'success',
                        message: 'Changes saved.',
                    });
                    // Refresh redux state with integration settings value
                    settingGetAction(
                        {
                            companyAccessCode: uid,
                            integration_id: settingId,
                        },
                        { loader: true },
                        token
                    );
                }
            );
        },
    });

    const isItAllowedAdmin = checkAdminAccess(loggedInAdminRole, [COMPANY_OWNER, COMPANY_ADMIN]);

    return (
        <>
            <Hidden xsDown>
                <HistoryBackArrow />
            </Hidden>

            <Typography className={classes.integrationText_1}>Hostaway</Typography>
            <Typography className={classes.integrationText_2}>
                Manage unlimited vacation rental channel reservations from check-in to check-out by
                one platform.{' '}
                <a
                    href="https://info.authenticate.com/hostaway-integration"
                    target="_blank"
                    rel="noreferrer"
                >
                    Learn more
                </a>
            </Typography>

            <form onSubmit={formik.handleSubmit}>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    className={classes.row1}
                >
                    <Grid item xs={6} md={3} className={classes.activetext}>
                        <span>Active</span>
                    </Grid>
                    <Grid item xs={6} md={9}>
                        <Switch
                            onChange={switchHandler}
                            checked={isActive}
                            disabled={!isItAllowedAdmin}
                            classes={{
                                switchBase: classes.switchBase,
                                track: isActive ? classes.activeTrack : classes.track,
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2} className={classes.row2}>
                    <Grid item xs={12} md={3} className={classes.activetext}>
                        <span>Hostaway API Key</span>
                    </Grid>
                    <Grid container item xs={12} md={9}>
                        <Grid item xs={12} md={6}>
                            <CredentialInput
                                classes={classes}
                                name="apiKey"
                                value={formik.values.apiKey}
                                handleChange={formik.handleChange}
                                error={formik.touched.apiKey && Boolean(formik.errors.apiKey)}
                                helperText={formik.touched.apiKey && formik.errors.apiKey}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={3} className={classes.activetext}>
                        <span>Hostaway Account Id</span>
                    </Grid>
                    <Grid container item xs={12} md={9}>
                        <Grid item xs={12} md={6}>
                            <CredentialInput
                                classes={classes}
                                name="accountId"
                                value={formik.values.accountId}
                                handleChange={formik.handleChange}
                                error={formik.touched.accountId && Boolean(formik.errors.accountId)}
                                helperText={formik.touched.accountId && formik.errors.accountId}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <VerifyAccessLevel allowedAdmins={[COMPANY_OWNER, COMPANY_ADMIN]}>
                    <Grid className={classes.btnGroup}>
                        <Button
                            variant={false}
                            btnText="Cancel"
                            className={classes.cancelButton}
                            handleOnClick={() => {
                                history.goBack();
                            }}
                        />
                        <Button
                            variant={false}
                            btnText="Save"
                            type="submit"
                            className={clsx(classes.saveButton, classes.cancelButton)}
                        />
                    </Grid>
                </VerifyAccessLevel>
            </form>
        </>
    );
};

Hostaway.propTypes = {
    token: PropTypes.string,
    uid: PropTypes.string,
    history: PropTypes.object,
    active: PropTypes.array,
    settings: PropTypes.array,
    settingsValues: PropTypes.array,
    settingAction: PropTypes.func,
    settingGetAction: PropTypes.func,
    settingPostAction: PropTypes.func,
    activeAction: PropTypes.func,
    loggedInAdminRole: PropTypes.string,
    openToast: PropTypes.func,
};

export default Hostaway;
