import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';

import { Grid, Hidden, IconButton } from '@material-ui/core';
import Dashboard from 'layouts/Dashboard';
import PropTypes from 'prop-types';
import PrivateRoute from 'route/PrivateRoute';

import { refreshCompanyAction } from 'redux/actions/auth';
import {
    activeAction,
    settingAction,
    settingGetAction,
    settingPostAction,
} from 'redux/actions/integrations';
import { openToast } from 'redux/actions/toaster';

import classes from './index.module.css';

import { ReactComponent as Menu } from 'asset/icons/dark_menu.svg';

import Hostaway from '../../V2/Integration/integrations/Hostaway';
import IntegrationCards from '../Integration/components/IntegrationCards';
import Ecommerce from './integrations/Ecommerce';
import { PaymentStatus } from './integrations/Ecommerce/components/PaymentStatus';
import Products from './integrations/Ecommerce/components/RutterProducts';
import Guesty from './integrations/Guesty';

const IntegrationPage = (props) => {
    return (
        <Grid className={classes.integrationWrapper}>
            <Hidden smUp>
                <div>
                    <IconButton onClick={props.handleToggler} className={classes.toggleBtn}>
                        <Menu />
                    </IconButton>
                </div>
            </Hidden>
            <Switch>
                <Route
                    path={`${props.match.path}`}
                    render={() => (
                        <Dashboard>
                            <IntegrationCards {...props} />
                        </Dashboard>
                    )}
                    exact
                />
                <PrivateRoute
                    path={`${props.match.path}/guesty`}
                    component={() => <Guesty {...props} />}
                />
                <Route
                    path={`${props.match.path}/hostaway`}
                    render={() => (
                        <Dashboard>
                            <Hostaway {...props} />
                        </Dashboard>
                    )}
                    exact
                />
                <Route
                    path={`${props.match.path}/e-commerce/payment`}
                    render={() => (
                        <Dashboard>
                            <PaymentStatus
                                {...props}
                                integrationSetting={props?.active?.filter((service) => {
                                    return service.name === 'E-Commerce';
                                })}
                            />
                        </Dashboard>
                    )}
                    exact
                />
                <Route
                    path={`${props.match.path}/e-commerce`}
                    render={() => (
                        <Dashboard>
                            <Ecommerce
                                {...props}
                                isIntegrated={props?.active?.filter((service) => {
                                    return service.name === 'E-Commerce';
                                })}
                            />
                        </Dashboard>
                    )}
                    exact
                />
                <Route
                    path={`${props.match.path}/products`}
                    render={() => (
                        <Dashboard>
                            <Products {...props} />
                        </Dashboard>
                    )}
                    exact
                />
            </Switch>
        </Grid>
    );
};

IntegrationPage.propTypes = {
    classes: PropTypes.object,
    handleToggler: PropTypes.func,
    match: PropTypes.object,
    active: PropTypes.array,
};

function mapStateToProps(state) {
    return {
        token: state?.auth?.userData?.token,
        uid: state?.auth?.userData?.user?.company?.uuid,
        settings: state?.integration?.settings,
        active: state.integration?.active,
        settingsValues: state?.integration?.settingsValues,
        authorized: state.auth?.userData?.user?.company?.authorized,
        loggedInAdminRole: state.auth?.userData.user.type,
    };
}

export default connect(mapStateToProps, {
    activeAction,
    settingAction,
    settingPostAction,
    openToast,
    settingGetAction,
    refreshCompanyAction,
})(withRouter(IntegrationPage));
