const { makeStyles } = require('@material-ui/core');

const useCustomSelectStyles = makeStyles((theme) => ({
    inputLabel: {
        fontFamily: 'Rubik, sans-serif',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '20px',
        textAlign: 'left',
        color: '#1d2939',
    },

    placeholderItem: {
        display: 'none',
    },

    select: {
        width: '100%',
        borderRadius: '8px',
        opacity: '0px',
        boxShadow: '0px 1px 2px 0px #1018280d',
        outline: 'none',
        marginTop: '6px',
        border: '1px solid #E5E7EB',

        '&::before, &::after': {
            borderBottom: '0px',
        },

        '&:hover:not(.Mui-disabled)::before': {
            border: 'none',
        },

        '&:hover:not(.Mui-disabled)': {
            border: '1px solid #1BA1DB',
        },

        '& .MuiSelect-root.MuiSelect-select': {
            fontFamily: 'Rubik, sans-sreif',
            fontSize: '16px',
            fontWeight: 400,
            textAlign: 'left',
            padding: '10px 14px',

            '&:focus': {
                backgroundColor: 'transparent',
            },

            '&:hover': {
                borderBottom: 'none',
            },
        },

        '& .MuiSelect-icon': {
            '& path': {
                fill: '#98A2B3',
            },
            top: '40%',
            right: '14px',
        },
    },
    menuStyles: {
        '&::-webkit-scrollbar': {
            width: '6px',
            backgroundColor: '#d9d9d9',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#8c8c8c',
            borderRadius: '4px',
        },
    },

    menuItem: {
        padding: '5px 12px',
        gap: '10px',
        fontFamily: 'Rubik, sans-serif',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        textAlign: 'left',
        color: '#262626',
    },

    tooltipMenuItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        pointerEvents: 'none',
        color: '#DDDDDD !important',

        '& .MuiIconButton-root': {
            pointerEvents: 'auto',
            color: '#344054',
        },
    },
    errMsg: {
        fontFamily: 'Rubik, sans-serif',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        textAlign: 'left',
        color: '#D92D20',
        marginTop: '6px',
    },
}));

export default useCustomSelectStyles;
