import { cloneElement, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import {
    Avatar,
    Badge,
    IconButton,
    Typography,
    List,
    Toolbar,
    AppBar,
    Drawer,
    CssBaseline,
    ClickAwayListener,
    Paper,
    Grid,
    Hidden,
    Link,
    Box,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Sidebar from 'components/Sidebar';
import { menuItems } from 'helper/constant';
import { getAdminDetails, getInitials } from 'helper/utility';
import PropTypes from 'prop-types';
import PaymentInfoModal from 'views/AddPaymentMethodModal/PaymentInfoModal';

import { fetchAdminInformationAction } from 'redux/actions/auth';

import { styles } from './styles';

import { ReactComponent as MenuIcon } from 'asset/IconsPack/at_menu-01-filled.svg';
import { ReactComponent as LaunchIcon } from 'asset/IconsPack/at_share-03.svg';
import { ReactComponent as DashboardLogo } from 'asset/icons/authenticate_light.svg';
import { ReactComponent as NotificationsIcon } from 'asset/icons/notification.svg';
import NoNotification from 'asset/img/no_notification.png';

import './Dashboard.scss';

const Dashboard = (props) => {
    const {
        classes,
        children,
        adminData,
        fetchAdminInformationAction,
        token,
        mainContainerStyles,
    } = props;
    const [openNotification, setOpenNotification] = useState(null);
    const [mobileOpen, setMobileOpen] = useState(false);

    const { fullName } = getAdminDetails(props.user?.company, adminData);

    const handleToggler = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleClickNotification = (e) => {
        e.stopPropagation();
        setOpenNotification(true);
    };
    const handleCloseNotification = (e) => {
        setOpenNotification(false);
    };

    useEffect(() => {
        if (!adminData) {
            fetchAdminInformationAction(token);
        }
    }, [fetchAdminInformationAction, adminData, token]);

    const isMobile = window.innerWidth < 500;

    return (
        <div className={classes.root}>
            <Helmet>
                {/* temporary disabled in mobile */}
                {/* // TODO: Implement the solution before goes to prod */}
                {!isMobile && (
                    <script
                        type="text/javascript"
                        id="hs-script-loader"
                        async
                        defer
                        src="//js-na1.hs-scripts.com/6431620.js"
                    ></script>
                )}
            </Helmet>
            <PaymentInfoModal />
            {/* Mobile & tablet Drawer */}
            <Hidden only={['lg', 'xl']}>
                <Drawer
                    variant="temporary"
                    classes={{
                        paper: clsx(classes.mobileDrawer),
                    }}
                    open={mobileOpen}
                    onClose={handleToggler}
                >
                    <Grid className={classes.drawerProfile}>
                        <Avatar>{getInitials(fullName)}</Avatar>
                        <Typography className={classes.profileName}>{fullName}</Typography>
                    </Grid>
                    <List component="nav" className={clsx(classes.navList, classes.mobileNavList)}>
                        <Sidebar menuItem={menuItems} authData={props.user} />
                    </List>
                </Drawer>
            </Hidden>

            {/* Desktop Drawer */}
            <Hidden mdDown>
                <Drawer
                    variant="permanent"
                    classes={{
                        paper: clsx(classes.drawerPaper, 'drawer'),
                    }}
                    open={mobileOpen}
                    onClose={handleToggler}
                >
                    <div className={classes.toolbarIcon} style={{ justifyContent: 'start' }}>
                        <DashboardLogo height={'16px'} width="auto" />
                    </div>
                    <List component="nav" className={clsx(classes.navList)}>
                        <Sidebar menuItem={menuItems} authData={props.user} />
                    </List>
                </Drawer>
            </Hidden>

            {/* Desktop and Tablet Main Section */}
            <Hidden xsDown>
                <main className={classes.content}>
                    {/* Desktop and Tablet Appbar */}
                    <Hidden xsDown>
                        <CssBaseline />
                        <AppBar className={clsx(classes.appBar, 'appbar')}>
                            <Toolbar className={classes.toolbar}>
                                <Grid>
                                    <Hidden only={['xs', 'lg', 'xl']}>
                                        <IconButton
                                            className={classes.tabletMenuBtn}
                                            onClick={handleToggler}
                                        >
                                            <MenuIcon
                                                className="menuIcon"
                                                onClick={handleToggler}
                                            />
                                        </IconButton>
                                    </Hidden>
                                    <span className={classes.companyName}>
                                        {props.user.company.company_name}
                                    </span>
                                </Grid>

                                <Grid className={classes.headerRightHalf}>
                                    <Grid className="linkBox">
                                        <Link
                                            href="https://docs.authenticate.com/"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="docLink"
                                        >
                                            Docs
                                            <LaunchIcon className="docIcon" />
                                        </Link>
                                    </Grid>

                                    <IconButton
                                        color="inherit"
                                        onClick={handleClickNotification}
                                        className="notificationButton"
                                    >
                                        <Badge color="secondary" overlap="rectangular">
                                            <NotificationsIcon className="notificationIcon" />
                                        </Badge>
                                    </IconButton>

                                    {openNotification && (
                                        <ClickAwayListener onClickAway={handleCloseNotification}>
                                            <Paper className={classes.popperWrapper}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            align="center"
                                                            className={classes.popperText_1}
                                                        >
                                                            {' '}
                                                            No notifications yet!
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography align="center">
                                                            <img
                                                                src={NoNotification}
                                                                alt="No Notification"
                                                            />
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            align="center"
                                                            className={classes.popperText_2}
                                                        >
                                                            We’ll notifiy you when something
                                                            arrives!
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </ClickAwayListener>
                                    )}

                                    <Box className="userAvatar">
                                        <Avatar className="userIcon">
                                            {getInitials(fullName)}
                                        </Avatar>
                                        <Typography className="userName">{fullName}</Typography>
                                    </Box>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </Hidden>
                    <Box className={clsx(mainContainerStyles, classes.container)}>
                        <Box className={classes.innerContainer}>{children}</Box>
                    </Box>
                </main>
            </Hidden>
            {/* Mobile Main Section */}
            <Hidden smUp>
                <main className={classes.content}>
                    <div>{cloneElement(children, { handleToggler })}</div>
                </main>
            </Hidden>
        </div>
    );
};

Dashboard.propTypes = {
    user: PropTypes.object.isRequired,
    classes: PropTypes.object,
    children: PropTypes.node,
    adminData: PropTypes.bool,
    fetchAdminInformationAction: PropTypes.func,
    token: PropTypes.string,
    mainContainerStyles: PropTypes.string,
};

function mapStateToProps(state) {
    return {
        user: state.auth?.userData?.user,
        adminData: state.auth?.adminData,
        token: state.auth?.userData.token,
    };
}

export default connect(mapStateToProps, { fetchAdminInformationAction })(
    withStyles(styles)(Dashboard)
);
