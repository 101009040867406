import { createTheme } from '@material-ui/core/styles';

const theme = createTheme();
const drawerWidth = 215;

export const styles = {
    root: {
        display: 'flex',
        height: '100vh',
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '18px 24px',
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        background: '#FFFFFF',
        boxShowdow: 'none!important',
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        boxShadow: 'none',
        color: 'black',
        position: 'static',
        [theme.breakpoints.up('sm')]: {
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
    },
    headerRightHalf: {
        display: 'flex',
        gap: '24px',
        alignItems: 'center',
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        minHeight: '100vh',
        background: '#154683',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    navList: {
        position: 'static',
    },
    mobileNavList: {
        paddingBottom: '60px',
    },
    mobileDrawer: {
        borderTopRightRadius: '8px',
        borderBottomRightRadius: '8px',
        overflowX: 'hidden',
    },
    tabletDrawerWrapper: {
        position: 'relative',
    },
    tabletDrawer: {
        width: drawerWidth,
        zIndex: 1200,
        background: '#154683',
        boxShadow: 'none',
    },
    tabletCloseBtn: {
        position: 'absolute',
        top: '1rem',
        right: '0',
        background: '#F2F4F7',
        height: '34px',
        width: '34px',
        padding: 0,
        transition: 'all 0.2s ease',
    },
    tabletCloseBtnOpen: {
        right: '-16.5rem',
        zIndex: 1301,
    },
    closeIcon: {
        height: 20,
        width: 20,
        color: '#171717',
    },
    content: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
    },
    container: {
        padding: '36px 44px 75px',
        flexGrow: '1',
    },
    innerContainer: {
        maxWidth: '1280px',
        margin: '0 auto',
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    popperWrapper: {
        width: '234px',
        padding: '20px 30px 30px 30px',
        position: 'absolute',
        right: '200px',
        top: '50px',
        zIndex: 1,
        boxShadow: ` 0px 1px 10px rgb(0 0 0 / 10%)`,
        borderRadius: '8px',
        border: '1px solid #F3F4F6',
    },
    popperText_1: {
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '19px',
        color: '#002C41',
    },
    popperText_2: {
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: 'nromal',
        fontSize: '14px',
        lineHeight: '17px',
        color: '#4B5563',
    },
    drawerProfile: {
        margin: '34px auto 28px 24px',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
    },
    profileName: {
        fontFamily: 'Rubik',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#3A3A3A',
    },
    companyName: {
        fontWeight: '500',
    },
    tabletMenuBtn: {
        marginRight: 14,
    },
};
