const apiEndpoints = {
    login: 'admin/login',
    login2FA: 'admin/login/2fa',
    terms: 'admin/consent',
    twoFAResend: 'admin/login/2fa/resend',
    register: 'admin/register',
    reset: 'admin/request/password/reset',
    refreshCompany: 'admin/company',
    verify: 'admin/validate/reset/token/',
    setForgetPassword: 'admin/forgot/password/reset',
    updatePassword: 'admin/reset/password',
    logout: 'admin/logout',
    users: 'admin/company/users',
    admin: 'admin/company/admin/self',
    active: 'admin/company/integrations/active',
    userLog: 'admin/company/user/log',
    getPaymentMethods: 'admin/payment/get-methods',
    getStripeCustomer: 'admin/payment/get-customer',
    setupIntent: 'admin/payment/setup-intent',
    createMethod: 'admin/payment/methods',
    removeCard: 'admin/payment/methods/delete',
    extendedCheckStatus: 'admin/users/criminal/check/status',
    countyHistory: 'admin/nc/identity/user/search/counties',
    searchCounties: 'admin/nc/user/county/price/search',
    countyCriminal: 'admin/nc/user/verify/criminal/county',
    countyCivil: 'admin/nc/user/verify/civil/county',
    federalCriminal: 'admin/nc/user/verify/criminal/federal',
    federalCivil: 'admin/nc/user/verify/civil/federal',
    extendedCheckCaseReport: 'admin/nc/user/verify/criminal/county/report',
    setting: 'admin/integration',
    settingGet: 'admin/company/integration/settings',
    getProducts: 'admin/integrations/rutter/products',
    getEnabledProducts: 'admin/integrations/rutter/products/monitor/enabled',
    setEnabledProducts: 'admin/integrations/rutter/products/monitor/update',
    getRutterPaymentStatus: 'admin/integrations/rutter/payment/plan',
    setRutterPaymentStatus: 'admin/integrations/rutter/payment/plan/confirm',
    isRutterProductsReady: 'admin/integration',
    signDocumentStatus: 'admin/sign/document/status',
    signDocumentSession: 'admin/sign/document/session',
    signDocumentInitiate: 'admin/sign/document/initiate',
    settings: {
        customSenderEmail: {
            default: 'admin/settings/custom-sender',
        },
        email: 'admin/settings/email/notifications',
        lowCodeScripts: {
            base: 'admin/settings/low-code-script/settings',
            update: 'admin/settings/low-code-script/settings/update',
            disable: 'admin/settings/low-code-script/settings/disable',
            reactivate: 'admin/settings/low-code-script/settings/re-activate',
        },
    },
    addWebhookUrl: 'admin/settings/account/webhook',
    accessCode: 'admin/settings/account/access-code',
    //userProfile dashboard api
    getBearerToken: 'admin/company/user/token',
    getTestResult: 'admin/nc/user/getTestResult',
    getSevenYearCriminalData: 'admin/nc/identity/request/criminal/report/seven',
    getJwtToken: 'admin/nc/user/jwt',
    generateCriminalReport: 'admin/nc/user/generateCriminalReport',
    fetchSevenYearCriminalData: 'admin/nc/identity/fetch/criminal/report/seven',
    getUser: 'admin/nc/user/summary',
    updateUser: 'admin/nc/user/update',
    verifyUploadId: 'admin/nc/identity/document/scan/data',
    verifyUploadPassport: 'admin/nc/identity/passport/scan/data',
    verifyBankAccountOwner: 'admin/nc/identity/bank/account/scan/data',

    // Billing Dashboard
    invoiceData: 'admin/billing/invoices',
    billingData: 'admin/billing/payments/create-session',
    currentCredit: 'admin/billing/usage/month',
    billingPlans: 'admin/billing/plans/active',
    // new signup
    verifyEmail: 'admin/verify-email',
    resendVerificationEmail: 'admin/resend/verify-email',
    updateEmail: 'admin/email',
    // Users Dashboard
    userFilter: 'admin/user/search',
    getLatestJwtSetting: 'admin/users/fetchJwtOptions/latest',
    sendEmailViaLink: 'admin/users/sendLinkViaEmail',
    createUser: 'admin/nc/user/create',
    // workflows
    workflowUrl: 'admin/workflow',
    // TCM
    watchlist: 'admin/company/service/monitoring/watchlist',
    tcmUserSearch: 'admin/company/user/search',
    getTcmRecords: 'admin/company/service/monitoring/record/user',
    getTcmStatus: 'admin/company/service/monitoring/status',
    addToTcm: 'admin/company/service/monitoring',
    removeFromTcm: 'admin/company/service/monitoring',

    //RBAC
    getAdmins: 'admin/company/admin-account/admins',
    createNewMember: 'admin/company/admin-account/create',
    setInvitePassword: 'admin/company/admin-account/verify-invite',
    // EEP
    // verification request
    educationRequest: 'admin/nc/education/v2/verify', //v2
    employerRequest: 'admin/nc/employment/v2/verify', //v2
    professionalLicenseRequest: 'admin/nc/identity/professional/license', //v1
    // verification result
    educationVerificationResult: 'admin/nc/education/v2/result', //v2
    employmentVerificationResult: 'admin/nc/employment/v2/result', //v2
    professionalLicenseVerificationResult: 'admin/nc/identity/professional/license/result', //v1
    // other eep forms API
    getSchoolName: 'admin/nc/education/school/names',
    getDegreeName: 'admin/nc/education/degree/names',
    getLicenseTitle: 'admin/nc/identity/professional/license/names',
    // LVA
    eventLogs: 'admin/company/user-event-logs',
    // Home Page Redesign
    statistics: 'admin/dashboard/statistics',
    graphStatistics: 'admin/dashboard/statistics/graph',
    // consent
    consent: 'admin/nc/user/consent',
    // SSN
    verifySsn: 'admin/nc/user/verify/ssn',
    //user pdf report
    initiatePdfReport: 'admin/nc/user/initiate/pdf/report',
    fetchPdfReportLink: 'admin/nc/user/retrieve/pdf/report',

    //mvr
    identityMvr: 'admin/nc/identity/mvr',
    fetchMvr: 'admin/nc/identity/fetch/mvr',

    //brands
    getBrand: '/admin/integration/guesty/brand',
    getListings: '/admin/integration/guesty/listings',
    moveListings: '/admin/integration/guesty/move/listings',
    listBrand: '/admin/integration/guesty/brands',
};

export default apiEndpoints;
